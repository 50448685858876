import { db } from '@/firebase/db.js'
import { getCPI } from '../utils/cpi'
let cpiRef = db.collection('cpi')
export default {
  data: () => ({
    CPI: {
      data: []
    }
  }),
  created() {
    this.loadCPI()
  },
  methods: {
    loadCPI(doc = 'default') {
      cpiRef
        .doc(doc)
        .get()
        .then(res => {
          this.CPI.data = res.data().data
          this.$store.commit('cpi', res.data().data)
        })
    },
    CPIOf(year) {
      return getCPI(this.CPI.data, year)
    }
  }
}
