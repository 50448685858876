const getCPI = (arr, year) => {
  let res = null
  arr.forEach(item => {
    if (item.year === year) {
      res = item.cpi
    }
  })
  return res
}
export { getCPI }
