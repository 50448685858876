<template>
  <base-tax-form @submit="addTaxHistory"></base-tax-form>
</template>

<script>
import BaseTaxForm from './BaseTaxForm'
import tax from '@/mixins/tax'
export default {
  mixins: [tax],
  components: {
    BaseTaxForm: BaseTaxForm
  },
  methods: {
    addTaxHistory(e) {
      this.$store.commit('addTaxHistory', { ...e.formData })
      e.resetForm()
      this.$emit('updated')

      this.updateMetrics({ feature: 'tax', action: 'create' })
    }
  }
}
</script>

<style></style>
