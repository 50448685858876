<template>
  <div>
    <v-form ref="form" v-model="valid" @submit.prevent="submit()">
      <input type="submit" hidden />
      <v-select
        class="mt-5"
        dense
        outlined
        :label="`Tax Year (Fiscal Year) to ${edit ? 'Edit' : 'Add'}`"
        :items="notSelected(CPI.data)"
        item-text="year"
        item-value="year"
        v-model="formData.year"
        :rules="rules.requiredRule"
        @change="setCPI"
      ></v-select>
      <fieldset class="form-fieldset rounded-lg" style="margin-top: -1rem">
        <legend class="mx-auto px-3 title primary--text">Real Estate</legend>
        <div class="pa-2">
          <v-text-field
            autofocus
            dense
            outlined
            type="number"
            label="Real Estate Tax (Full Year)"
            prefix="$"
            :rules="rules.positiveNumber"
            v-model="formData.real_estate_tax"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                type="number"
                label="Taxable Value"
                :rules="rules.positiveNumber"
                v-model="formData.real_estate_taxable"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                label="State Class"
                v-model="formData.state_class"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-slide-y-transition>
            <div class="mt-n9" v-if="showResTaxExemption">
              <v-checkbox
                v-model="formData.residentialTaxExemption"
                label="Granted a Residential Tax Exemption"
              ></v-checkbox>
            </div>
          </v-slide-y-transition>
          <div class="d-lg-flex justify-space-between">
            <div>
              <v-checkbox
                style="margin-top: -10px"
                label="I have a Real Estate Tax bill to archive"
                v-model="formData.real_estate_bill.exists"
                @change="changeRealEstateBill"
              ></v-checkbox>
            </div>
            <div
              class="d-flex flex-wrap justify-space-around"
              style="margin-top: -10px"
            >
              <real-estate-tax-bill
                style="margin-top: -4px"
                text
                color="success"
              ></real-estate-tax-bill>
              <div class="mb-3">
                <swift-scan
                  doc="tax bill"
                  docNumber="Real Estate Tax bill"
                ></swift-scan>
              </div>
            </div>
          </div>
          <v-slide-y-transition>
            <div v-show="formData.real_estate_bill.exists" class="mt-5">
              <file-upload
                :showDescription="true"
                :description.sync="bills.description.real_estate"
                :error.sync="bills.errors.real_estate"
                :file.sync="bills.real_estate"
                :report="formData.real_estate_bill"
              ></file-upload>
            </div>
          </v-slide-y-transition>
        </div>
      </fieldset>

      <fieldset class="form-fieldset rounded-lg">
        <legend class="mx-auto px-3 title primary--text">
          Personal Property
        </legend>
        <div class="pa-2">
          <v-text-field
            outlined
            dense
            label="Personal Property Tax"
            :rules="rules.positiveNumber"
            v-model="formData.personal_property_tax"
            prefix="$"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            type="number"
            label="Taxable Value"
            :rules="rules.positiveNumber"
            v-model="formData.personal_property_taxable"
            prefix="$"
          ></v-text-field>
          <div class="d-lg-flex d-block justify-space-between">
            <div>
              <v-checkbox
                style="margin-top: -10px"
                label="I have a Personal Property Tax bill to archive"
                v-model="formData.personal_property_bill.exists"
                @change="changePersonalPropertyBill"
              ></v-checkbox>
            </div>
            <div
              class="d-flex flex-wrap justify-space-around"
              style="margin-top: -10px"
            >
              <personal-property-tax-bill
                style="margin-top: -4px"
                text
                color="success"
              ></personal-property-tax-bill>
              <div>
                <swift-scan
                  doc="tax bill"
                  docNumber="Personal Property Tax bill"
                ></swift-scan>
              </div>
            </div>
          </div>
          <v-slide-y-transition>
            <div v-show="formData.personal_property_bill.exists" class="mt-5">
              <file-upload
                :showDescription="true"
                :description.sync="bills.description.personal_property"
                :error.sync="bills.errors.personal_property"
                :file.sync="bills.personal_property"
                :report="formData.personal_property_bill"
              ></file-upload>
            </div>
          </v-slide-y-transition>
        </div>
      </fieldset>
      <fieldset class="form-fieldset rounded-lg">
        <legend class="mx-auto px-3 title primary--text">Changes</legend>
        <div class="pa-2">
          <v-text-field
            dense
            type="number"
            label="Improvements"
            prefix="$"
            outlined
            :rules="rules.positiveNumber"
            v-model="formData.improvement"
          ></v-text-field>
          <v-text-field
            dense
            type="number"
            label="Maintenance/Repair"
            prefix="$"
            outlined
            :rules="rules.positiveNumber"
            v-model="formData.maintenance_repair"
          ></v-text-field>
          <v-textarea
            dense
            outlined
            label="Description"
            rows="3"
            auto-grow
            v-model="formData.improvement_description"
          ></v-textarea>
        </div>
      </fieldset>
    </v-form>
    <div class="mt-2">
      <slot name="alert"></slot>
    </div>
    <div class="mt-2">
      <v-alert v-if="error" type="error">{{ error }}</v-alert>
    </div>
    <div class="text-center mt-2">
      <v-btn :loading="loading" color="primary" @click="submit"
        ><slot name="submit-btn"
          >Save <v-icon>mdi-content-save</v-icon></slot
        ></v-btn
      >
      <span @click="cancel()"><cancel-button></cancel-button></span>
      <br />
      <!-- <div class="d-md-flex d-block justify-space-between"> -->
    </div>
  </div>
</template>

<script>
import cpi from '@/mixins/cpi'
import rules from '@/mixins/rules'
import audio from '@/mixins/audio'
import { parcelRef } from '@/firebase/db'
import RealEstateTaxBill from '@/components/RealEstateTaxBill'
import PersonalPropertyTaxBill from '@/components/PersonalPropertyTaxBill'
import FileUpload from '@/components/FileUpload'
import utils from '@/mixins/utils'
import { mapGetters } from 'vuex'
export default {
  mixins: [cpi, rules, audio, utils],
  props: ['data', 'edit'],
  components: {
    RealEstateTaxBill,
    PersonalPropertyTaxBill,
    FileUpload
  },
  data: () => ({
    defaultFormData: {
      year: null,
      tax: null,
      improvement: null,
      maintenance_repair: null,
      state_class: '',
      real_estate_bill: {},
      personal_property_bill: {}
    },
    formData: {},
    valid: true,
    defaultStateClass: '',
    bills: {
      real_estate: null,
      personal_property: null,
      errors: {
        real_estate: false,
        personal_property: false
      },
      description: {
        real_estate: '',
        personal_property: ''
      }
    },
    loading: false,
    error: null
  }),
  computed: {
    ...mapGetters(['taxHistory', 'activeTax', 'latestParcel']),
    showResTaxExemption() {
      let res = false
      if (this.latestParcel && this.latestParcel.parcel_type == 'resPrimary') {
        if (this.formData.year >= 2018) {
          res = true
        }
      }
      return res
    }
  },
  created() {
    this.formData = this.copyObject(this.defaultFormData)
  },
  async mounted() {
    if (this.data) {
      this.formData = {
        ...this.defaultFormData,
        ...this.copyObject(this.data)
      }
    } else {
      let stateClass = await this.getStateClass(this.$route.params.id)
      if (!stateClass) {
        stateClass = ''
      }
      this.defaultStateClass = stateClass
      this.formData.state_class = this.defaultStateClass
    }
  },
  methods: {
    async getStateClass(parcel) {
      let parcelDetail = await parcelRef.doc(parcel).get()
      return parcelDetail.data().state_class
    },
    resetForm() {
      this.formData = {
        ...this.defaultFormData,
        state_class: this.defaultStateClass
      }
      this.$refs.form.resetValidation()
    },
    async uploadBills() {
      if (this.bills.real_estate) {
        let re_bill = await this.uploadFile(this.bills.real_estate, {
          description: this.bills.description.real_estate,
          feature: 'tax',
          parcelID: this.$route.params.id || ''
        })
        this.formData.real_estate_bill = {
          ...this.formData.real_estate_bill,
          ...re_bill
        }
        this.bills.real_estate = null
      }
      if (this.bills.personal_property) {
        let pp_bill = await this.uploadFile(this.bills.personal_property, {
          description: this.bills.description.personal_property,
          feature: 'tax',
          parcelID: this.$route.params.id || ''
        })
        this.formData.personal_property_bill = {
          ...this.formData.personal_property_bill,
          ...pp_bill
        }
        this.bills.personal_property = null
      }
    },
    async submit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.error = null
        if (
          !this.formData.real_estate_tax &&
          !this.formData.personal_property_tax
        ) {
          this.valid = false
          this.error =
            'Either Real Estate Tax or Personal Property Tax is required'
        } else {
          this.valid = true
        }
      }
      if (this.valid) {
        this.loading = true
        if (this.formData.tax != null) {
          this.formData.tax = Number(this.formData.tax)
        }

        let total_tax = 0
        if (this.formData.real_estate_tax) {
          total_tax += Number(this.formData.real_estate_tax)
        }
        if (this.formData.personal_property_tax) {
          total_tax += Number(this.formData.personal_property_tax)
        }
        this.formData.tax = total_tax
        if (this.formData.improvement) {
          this.formData.improvement = Number(this.formData.improvement)
        }

        let total_taxable = 0
        if (this.formData.real_estate_taxable) {
          total_taxable += Number(this.formData.real_estate_taxable)
        }
        if (this.formData.personal_property_taxable) {
          total_taxable += Number(this.formData.personal_property_taxable)
        }
        this.formData.taxable_value = total_taxable

        if (!this.edit) {
          this.formData.timestampCreated = new Date()
        }
        this.formData.timestampModified = new Date()

        if (this.formData.maintenance_repair) {
          this.formData.maintenance_repair = Number(
            this.formData.maintenance_repair
          )
        }
        await this.uploadBills()
        this.playCash()
        this.loading = false
        this.$emit('submit', this)
      }
    },
    setCPI(data) {
      this.formData.cpi = this.CPIOf(data)
      this.$emit('updateYear', this.formData.year)
    },
    notSelected(data) {
      let res = [...data]
      res = res.filter(e => {
        let match = this.$store.getters.taxHistory.some(f => {
          return f.year == e.year
        })
        if (this.edit) {
          return match
        } else {
          return !match
        }
      })

      res.sort((a, b) => {
        return b.year - a.year
      })
      return res
    },
    changeRealEstateBill() {
      if (!this.formData.real_estate_bill.exists) {
        this.formData.real_estate_bill = {}
        this.bills.real_estate = null
      }
    },
    changePersonalPropertyBill() {
      if (!this.formData.personal_property_bill.exists) {
        this.formData.personal_property_bill = {}
        this.bills.personal_property = null
      }
    },
    cancel() {
      this.resetForm()
      this.$emit('cancel')
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.formData = {
          ...this.defaultFormData,
          ...this.copyObject(this.data)
        }
      } else {
        this.resetForm()
      }
    }
  }
}
</script>

<style></style>
