<template>
  <div>
    <v-dialog v-model="dlt.dialog" max-width="400">
      <v-card class="mx-auto">
        <v-card-title></v-card-title>
        <v-card-text>
          Are you sure? Do you want to delete this item?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteItem()">Yes</v-btn
          ><v-btn color="secondary" text @click="dlt.dialog = false">No</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-data-table
      :loading="loading"
      :headers="headers"
      :items="taxHistory"
      class="elevation-1"
      dense
    >
      <template v-slot:[`item.maintenance_repair`]="{ item }">
        {{ fmtNum(item.maintenance_repair) }}

        <v-tooltip bottom v-if="item.improvement_description">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              mdi-file-document-outline
            </v-icon>
          </template>
          <span>{{ item.improvement_description }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.real_estate_tax`]="{ item }">
        <span>
          {{
            item.real_estate_tax
              ? Intl.NumberFormat().format(item.real_estate_tax)
              : ''
          }}
          <span v-if="item.residentialTaxExemption">RTE</span>
        </span>
        <v-tooltip
          bottom
          v-if="
            typeof item.real_estate_bill == 'object' &&
            item.real_estate_bill.url
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="newWindow(item.real_estate_bill.url)"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-file-document-outline
            </v-icon>
          </template>
          <span>{{ item.real_estate_bill.filename }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.personal_property_tax`]="{ item }">
        <span>
          {{ fmtNum(item.personal_property_tax) }}
        </span>
        <v-tooltip
          bottom
          v-if="
            typeof item.personal_property_bill == 'object' &&
            item.personal_property_bill.url
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="newWindow(item.personal_property_bill.url)"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-file-document-outline
            </v-icon>
          </template>
          <span>{{ item.personal_property_bill.filename }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.real_estate_taxable`]="{ item }">
        {{
          item.real_estate_taxable
            ? Intl.NumberFormat().format(item.real_estate_taxable)
            : ''
        }}
      </template>
      <template v-slot:[`item.personal_property_taxable`]="{ item }">
        {{ fmtNum(item.personal_property_taxable) }}
      </template>
      <template v-slot:[`item.improvement`]="{ item }">
        {{ fmtNum(item.improvement) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom class="d-inline">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="info"
              @click="editItem(item.index)"
              ><slot name="icon">mdi-pencil</slot></v-icon
            >
          </template>
          <span><slot name="tooltip">Edit</slot></span>
        </v-tooltip>
        <v-tooltip bottom class="d-sm-none">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              @click="showDeleteDialog(item.index)"
              ><slot name="icon">mdi-trash-can</slot></v-icon
            >
          </template>
          <span><slot name="tooltip">Delete</slot></span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.cpi`]="{ item }">
        {{ item.cpi || CPIOf(item.year) }}
      </template>
    </base-data-table>
    <div class="d-flex justify-center">
      <p
        v-if="taxInfoHelp"
        v-html="taxInfoHelp"
        class="text-center info--text mt-3 tax-info-help"
      ></p>
    </div>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
import address from '@/mixins/address'
import parcel from '@/mixins/parcel'
import cpiMixin from '@/mixins/cpi'
import { mapGetters, mapMutations } from 'vuex'
export default {
  mixins: [utils, address, parcel, cpiMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: 'FY',
        align: 'center',
        sortable: false,
        value: 'year',
        class: 'primary white--text'
      },
      {
        text: 'Real Estate Tax $',
        align: 'center',
        sortable: false,
        value: 'real_estate_tax',
        class: 'primary white--text'
      },
      {
        text: 'Taxable Value $',
        align: 'center',
        value: 'real_estate_taxable',
        class: 'primary white--text',
        sortable: false
      },
      {
        text: 'Personal Property Tax $',
        align: 'center',
        sortable: false,
        value: 'personal_property_tax',
        class: 'primary white--text'
      },
      {
        text: 'Taxable Value $',
        align: 'center',
        value: 'personal_property_taxable',
        class: 'primary white--text',
        sortable: false
      },
      {
        text: 'Improvements $',
        align: 'center',
        sortable: false,
        value: 'improvement',
        class: 'primary white--text'
      },
      {
        text: 'Repairs $',
        align: 'center',
        sortable: false,
        value: 'maintenance_repair',
        class: 'primary white--text'
      },

      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'action',
        class: 'primary white--text',
        width: 80
      },
      {
        text: 'CPI-U',
        align: 'center',
        sortable: false,
        value: 'cpi',
        class: 'primary white--text'
      }
    ],
    dlt: {
      dialog: false,
      index: -1
    }
  }),
  computed: {
    isMD() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    taxInfoHelp() {
      if (this.$store.getters.latestParcel) {
        let currentParcel = this.$store.getters.latestParcel
        let addresses = this.$store.getters.addresses

        // filter state of current parcel
        let res = addresses.filter(e => {
          return e.text == currentParcel.state
        })

        // filter town/city
        if (res.length) {
          res = res[0].townsCities
          res = res.filter(e => {
            return e.text === currentParcel.townCity
          })
        }

        // set contact info of current town city
        if (res.length && res[0].taxInfoContact) {
          res = res[0].taxInfoContact
        } else {
          res = null
        }
        return res
      } else {
        return null
      }
    },
    ...mapGetters(['activeTax', 'taxHistory'])
  },
  methods: {
    ...mapMutations(['setActiveTax']),
    deleteItem() {
      if (this.dlt.index != -1) {
        this.$store.commit('removeTaxHistory', this.dlt.index)
        this.dlt.dialog = false
        this.dlt.index = -1
        this.setActiveTax(-1)
        this.$emit('updated')
        this.updateMetrics({ feature: 'tax', action: 'delete' })
      }
    },
    showDeleteDialog(index) {
      this.dlt.index = index
      this.dlt.dialog = true
    },
    editItem(index) {
      this.setActiveTax(index)
    }
  }
}
</script>

<style scoped>
.tax-info-help {
  border: 2px solid rgba(121, 116, 116, 0.445);
  padding: 8px;
  max-width: 50rem;
}
</style>
