var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dlt.dialog),callback:function ($$v) {_vm.$set(_vm.dlt, "dialog", $$v)},expression:"dlt.dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title'),_c('v-card-text',[_vm._v(" Are you sure? Do you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dlt.dialog = false}}},[_vm._v("No")]),_c('v-spacer')],1)],1)],1),_c('base-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.taxHistory,"dense":""},scopedSlots:_vm._u([{key:"item.maintenance_repair",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fmtNum(item.maintenance_repair))+" "),(item.improvement_description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.improvement_description))])]):_vm._e()]}},{key:"item.real_estate_tax",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.real_estate_tax ? Intl.NumberFormat().format(item.real_estate_tax) : '')+" "),(item.residentialTaxExemption)?_c('span',[_vm._v("RTE")]):_vm._e()]),(
          typeof item.real_estate_bill == 'object' &&
          item.real_estate_bill.url
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.newWindow(item.real_estate_bill.url)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.real_estate_bill.filename))])]):_vm._e()]}},{key:"item.personal_property_tax",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.fmtNum(item.personal_property_tax))+" ")]),(
          typeof item.personal_property_bill == 'object' &&
          item.personal_property_bill.url
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.newWindow(item.personal_property_bill.url)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.personal_property_bill.filename))])]):_vm._e()]}},{key:"item.real_estate_taxable",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.real_estate_taxable ? Intl.NumberFormat().format(item.real_estate_taxable) : '')+" ")]}},{key:"item.personal_property_taxable",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fmtNum(item.personal_property_taxable))+" ")]}},{key:"item.improvement",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fmtNum(item.improvement))+" ")]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item.index)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-pencil")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Edit")])],2)]),_c('v-tooltip',{staticClass:"d-sm-none",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){return _vm.showDeleteDialog(item.index)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-trash-can")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Delete")])],2)])]}},{key:"item.cpi",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.cpi || _vm.CPIOf(item.year))+" ")]}}],null,true)}),_c('div',{staticClass:"d-flex justify-center"},[(_vm.taxInfoHelp)?_c('p',{staticClass:"text-center info--text mt-3 tax-info-help",domProps:{"innerHTML":_vm._s(_vm.taxInfoHelp)}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }